import React from "react";
import { RichText } from "prismic-reactjs";
import moment from "moment-timezone";

import { Box, FlexGrid } from "src/components";

import ConcertItem from "./_concertItem";

function Grid({ items }) {
  return (
    <FlexGrid gutterX={[5]} gutterY={[5, 5, 5, 7]}>
      {items.map((item, index) => {
        if (item.type === "concert") {
          return (
            <Box width={[1, 1 / 2]} key={index}>
              {item.performerData && (
                <ConcertItem
                  heading={
                    item.performerData.artist_name &&
                    RichText.asText(item.performerData.artist_name)
                  }
                  featuring={
                    item.openerData &&
                    item.openerData.artist_name &&
                    (item.opener_importance === "Headliner"
                      ? " & "
                      : " with ") + RichText.asText(item.openerData.artist_name)
                  }
                  featuredProminent={
                    item.opener_importance === "Headliner" ? true : false
                  }
                  date={item.date && moment(item.date).format("MMM D, YYYY")}
                  venue={item.venue}
                  image={
                    item.performerData.image &&
                    item.performerData.image.url &&
                    item.performerData.image
                  }
                  featuringImage={
                    item.openerData &&
                    item.openerData.image &&
                    item.openerData.image.url &&
                    item.openerData.image
                  }
                  status={
                    item.ticket_state === "On Sale"
                      ? item.on_sale_date
                        ? moment(item.on_sale_date)
                            .tz("America/Edmonton")
                            .unix() > moment().tz("America/Edmonton").unix()
                          ? "On Sale " +
                            moment(item.on_sale_date)
                              .tz("America/Edmonton")
                              .format("MMM D")
                          : item.ticket_state
                        : item.ticket_state
                      : item.ticket_state
                  }
                  to={"/concert/" + item.uid}
                  type={!item.virtual_concert ? item.type : "Virtual Concert"}
                />
              )}
            </Box>
          );
        } else {
          return (
            <Box width={[1, 1 / 2]} key={index}>
              <ConcertItem
                heading={item.title && RichText.asText(item.title)}
                featuring={
                  item.performerData &&
                  item.performerData.artist_name &&
                  "With " + RichText.asText(item.performerData.artist_name)
                }
                date={
                  item.start_time &&
                  moment(item.start_time)
                    .tz("America/Edmonton")
                    .format("MMM D, YYYY") +
                    (item.end_time
                      ? item.start_time &&
                        (moment(item.end_time)
                          .tz("America/Edmonton")
                          .dayOfYear() ===
                        moment(item.start_time)
                          .tz("America/Edmonton")
                          .dayOfYear()
                          ? ""
                          : " - " +
                            moment(item.end_time)
                              .tz("America/Edmonton")
                              .format("MMMM Do h:mm A"))
                      : "")
                }
                venue={item.venue}
                image={item.image && item.image.url && item.image}
                status={
                  item.ticket_state === "On Sale"
                    ? item.on_sale_date
                      ? moment(item.on_sale_date)
                          .tz("America/Edmonton")
                          .unix() > moment().tz("America/Edmonton").unix()
                        ? "On Sale " +
                          moment(item.on_sale_date)
                            .tz("America/Edmonton")
                            .format("MMM D")
                        : item.ticket_state
                      : item.ticket_state
                    : item.ticket_state
                }
                to={"/event/" + item.uid}
                type={item.type}
              />
            </Box>
          );
        }
      })}
    </FlexGrid>
  );
}

export default Grid;
