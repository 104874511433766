import React from "react";

import { PageHeaderV2 } from "src/components";

function Intro({ intro, setInvert, setInvertLogo }) {
  return (
    <PageHeaderV2
      heading={intro}
      color="brand.altPrimary"
      invert={true}
      setInvertLogo={setInvertLogo}
      setInvert={setInvert}
    />
  );
}

export default Intro;
