import React from "react";
import { Link } from "gatsby";

import { Box, FlexRow, H4, H6, PrismicImage, Text } from "src/components";

const Tag = ({ children }) => (
  <Box
    bg="bg.reverse"
    css={`
      padding: 8px;
    `}
  >
    <H6 children={children} color="text.reverse" />
  </Box>
);

const Subtag = ({ children }) => (
  <Box
    bg="brand.primary"
    css={`
      padding: 8px;
    `}
  >
    <H6 children={children} color="text.reverse" />
  </Box>
);

const ConcertItem = ({
  venue,
  featuring,
  date,
  status,
  heading,
  image,
  featuringImage,
  type,
  to,
}) => (
  <Box
    css={`
      .featured-image {
        opacity: 0;
        transition: opacity 200ms ease-out;
      }
      :hover {
        .featured-image {
          opacity: 1;
        }
      }
    `}
  >
    <Link to={to}>
      <Box position="relative">
        <PrismicImage
          image={image}
          style={{ maxHeight: "512px", minHeight: "256px" }}
        />
        {featuringImage && (
          <FlexRow
            className="featured-image"
            css={`
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
            `}
          >
            <PrismicImage
              image={featuringImage}
              style={{ width: "100%", maxHeight: "512px", minHeight: "256px" }}
            />
          </FlexRow>
        )}
        <FlexRow
          css={`
            position: absolute;
            top: 0;
            left: 16px;
          `}
        >
          <Tag children={type} />
          <Subtag children={status} />
        </FlexRow>
      </Box>
    </Link>
    {heading && (
      <Text mt={3} fontWeight={600}>
        <Link children={heading + (featuring ? featuring : "")} to={to} />
      </Text>
    )}
    {(date || venue) && (
      <Text mt={1} fontSize={[0, 1]} color="text.alt">
        {date ? date : ""}
        {date && venue && ` · `}
        {venue ? venue : ""}
      </Text>
    )}
  </Box>
);

export default ConcertItem;
