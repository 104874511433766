import React from "react";

import { Box, H3 } from "src/components";

function Empty() {
  return (
    <Box py={[5, 7]} bg="bg.reverse">
      <Box maxWidth={600} mx="auto" my={6}>
        <H3
          children={`We don't have any upcoming shows yet. Stay tuned for more concert announcements!`}
          textAlign="center"
          color="text.reverse"
        />
      </Box>
    </Box>
  );
}

export default Empty;
