import React from "react";

import { Box, FlexRow, H2, H6, SectionWrapper } from "src/components";

import Grid from "./_grid";

const Series = ({ title, logo, sponsor, items }) => (
  <SectionWrapper my={[0, 8]} bg="bg.default">
    <Box mx={-5}>
      <Box p={5} bg="bg.wash">
        <Box>
          <FlexRow
            flexDirection={["column", "row"]}
            justifyContent={["center", "space-between"]}
            alignItems={["center", "flex-end"]}
          >
            <H2 mb={[3, 0]} textAlign={["center", "left"]} style={{ flex: 1 }}>
              {title}
            </H2>
            <FlexRow alignItems={["center", "flex-end"]}>
              <H6 color="text.alt">{sponsor}</H6>
              {logo && logo.url && (
                <Box
                  as="img"
                  src={logo.url}
                  alt={logo.alt}
                  ml={2}
                  height="auto"
                  maxWidth={64}
                />
              )}
            </FlexRow>
          </FlexRow>
        </Box>
        <Box width="100%" height="1px" my={4} bg="rgba(0, 0, 0, 0.2)" />
        <Box>
          <Grid items={items} />
        </Box>
      </Box>
    </Box>
  </SectionWrapper>
);

export default Series;
