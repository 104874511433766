import React from "react";
import moment from "moment-timezone";
import { StaticQuery, graphql } from "gatsby";
import { RichText } from "prismic-reactjs";

import { SectionWrapper, SEO } from "src/components";

import Intro from "./_components/_intro";
import Empty from "./_components/_empty";
import Grid from "./_components/_grid";
import Series from "./_components/_series";

const ConcertsPage = ({ setInvert, setInvertLogo, preview, seriesPreview }) => (
  <StaticQuery
    query={graphql`
      {
        concertPage: prismicConcerts {
          dataString
        }
        concerts: allPrismicConcer {
          edges {
            node {
              uid
              dataString
            }
          }
        }
        events: allPrismicEvent {
          edges {
            node {
              uid
              dataString
            }
          }
        }
        artists: allPrismicArtist {
          edges {
            node {
              uid
              dataString
            }
          }
        }
        series: allPrismicConcertSeries {
          edges {
            node {
              dataString
            }
          }
        }
      }
    `}
    render={(data) => {
      let page = JSON.parse(data.concertPage.dataString);
      if (preview) {
        page = preview;
      }
      let series = [];
      let items = [];
      data.concerts.edges.forEach((concert) => {
        let concertParsed = JSON.parse(concert.node.dataString);
        if (moment(concertParsed.date).unix() > moment().unix() - 86400) {
          let concertFinal = {
            uid: concert.node.uid,
            type: "concert",
            ...concertParsed,
          };
          data.artists.edges.forEach((artist) => {
            if (artist.node.uid === concertParsed.performer.uid) {
              concertFinal.performerData = JSON.parse(artist.node.dataString);
            }
            if (artist.node.uid === concertParsed.opener.uid) {
              concertFinal.openerData = JSON.parse(artist.node.dataString);
            }
          });
          items.push(concertFinal);
        }
      });
      data.events.edges.forEach((event) => {
        let eventParsed = JSON.parse(event.node.dataString);
        if (moment(eventParsed.start_time).unix() > moment().unix() - 86400) {
          let eventFinal = {
            uid: event.node.uid,
            type: "event",
            ...eventParsed,
          };
          data.artists.edges.forEach((artist) => {
            if (artist.node.uid === eventParsed.artist.uid) {
              eventFinal.performerData = JSON.parse(artist.node.dataString);
            }
          });
          items.push(eventFinal);
        }
      });
      items = items.sort((a, b) => {
        return (
          moment(a.date || a.start_time).unix() -
          moment(b.date || b.start_time).unix()
        );
      });
      if (data.series && data.series.edges) {
        data.series.edges.forEach((seriesItem) => {
          let seriesParsed = seriesItem;
          if (seriesItem.node) {
            seriesParsed = JSON.parse(seriesItem.node.dataString);
          }
          seriesParsed.items = [];
          if (seriesParsed.concerts) {
            seriesParsed.concerts.forEach(({ concert }) => {
              items.forEach((item) => {
                if (item.uid === concert.uid) {
                  seriesParsed.items.push(item);
                  item.series = true;
                }
              });
            });
          }
          series.push(seriesParsed);
        });
        if (seriesPreview) {
          seriesPreview.items = [];
          if (seriesPreview.concerts) {
            seriesPreview.concerts.forEach(({ concert }) => {
              items.forEach((item) => {
                if (item.uid === concert.uid) {
                  seriesPreview.items.push(item);
                  item.series = true;
                }
              });
            });
          }
          series.push(seriesPreview);
        }
      }
      series = series.sort((a, b) => {
        return (
          moment(a.series_start_date).unix() -
          moment(b.series_start_date).unix()
        );
      });
      items = items.sort((a, b) => {
        return (
          moment(a.date || a.start_time).unix() -
          moment(b.date || b.start_time).unix()
        );
      });
      return (
        <>
          <SEO
            image={
              page.opengraph_image &&
              page.opengraph_image.url &&
              page.opengraph_image.url
            }
            imageAlt={
              page.opengraph_image &&
              page.opengraph_image.alt &&
              page.opengraph_image.alt
            }
            title={
              page.opengraph_title && RichText.asText(page.opengraph_title)
            }
            description={
              page.opengraph_description &&
              RichText.asText(page.opengraph_description)
            }
          />
          <Intro
            intro={RichText.asText(page.intro)}
            setInvert={setInvert}
            setInvertLogo={setInvertLogo}
          />
          {items.length === 0 && <Empty />}
          {series && series.length > 0 && (
            <>
              {series.map(({ series_title, sponsor_logo, sponsor, items }) => (
                <Series
                  title={series_title && RichText.asText(series_title)}
                  logo={sponsor_logo}
                  sponsor={sponsor && RichText.asText(sponsor)}
                  items={items}
                />
              ))}
            </>
          )}
          {items && items.filter((item) => !item.series).length > 0 && (
            <SectionWrapper my={[7, 8]} bg="bg.default">
              <Grid items={items.filter((item) => !item.series)} />
            </SectionWrapper>
          )}
        </>
      );
    }}
  />
);

export default ConcertsPage;
